<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import Multiselect from 'vue-multiselect'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { BASE_IMAGE_URL } from '@src/config/configs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueEditor, Quill } from 'vue2-editor'
import ImageResize from 'quill-image-resize-module'

Quill.register('modules/imageResize', ImageResize)

/**
 * Contents component
 */
export default {
  page: {
    title: 'Contents',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    Multiselect,
    vueDropzone: vue2Dropzone,
    VueEditor,
    Loading,
  },
  data() {
    return {
      editorSettings: {
        modules: {
          imageResize: {},
        },
      },
      isLoading: false,
      baseImageUrl: BASE_IMAGE_URL,
      options: [],
      form: {
        status: true,
        content_title: '',
        content_text: '',
        image: '',
        inputtag: [],
        is_video: true,
        video: '',
        // short_description: '',
        is_send: false,
        notification_title: '',
        notification_text: '',
        type: 'exclusivetraining',
        iapProductId: null,
      },

      optionsStatus: [
        { value: true, text: 'show' },
        { value: false, text: 'hide' },
      ],
      optionsType: [
        { value: true, text: 'Paid' },
        { value: false, text: 'Free' },
      ],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 340,
        maxFilesize: 100,
        maxFiles: 1,
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
      },
      isEdit: false,
      ismanuallyadd: false,
    }
  },
  validations: {
    form: {
      content_title: {
        required,
      },
      content_text: {
        required,
      },
      image: {
        required,
      },
      video: {
        required,
      },
    },
  },
  mounted() {
    this.initialUpdateItem()
    this.getContenttags()
    this.getProductID()
  },
  methods: {
    async getProductID() {
      await axios
        .get(`/api/config?name=exclusivetraining_iapproductId`)
        .then((result) => {
          this.form.iapProductId = result.data.text
        })
    },
    doAjax() {
      this.isLoading = true
      // simulate AJAX
    },
    onCancel() {
      this.isLoading = false

      // console.log('User cancelled the loader.')
    },
    vfileAdded(file) {
      this.form.image = file
      this.ismanuallyadd = false
    },
    vfilemanuallyAdded(file) {
      this.ismanuallyadd = true
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    async getContenttags() {
      const data = await axios
        .get('/api/contenttag')
        .then((res) => res.data)
        .catch((err) => {
          this.$toast.error(err.response.data.error.message)
        })

      this.options = data
    },
    async formSubmit() {
      if (!this.form.is_video) {
        if (!this.form.image) {
          this.$toast.error('Image field must not be empty ')
        }
      }
      let id = []
      this.form.inputtag.map((contenttag) => {
        id.push(contenttag._id)
      })
      this.doAjax()

      let body = {
        content_title: this.form.content_title,
        content_text: this.form.content_text,
        // short_description: this.form.short_description,
        hashtag: id,
        status: this.form.status,
        is_paid: this.form.is_paid,
        is_video: this.form.is_video,
        type: this.form.type,
        iapProductId: this.form.iapProductId,
      }

      if (!this.isEdit && this.form.is_send) {
        body.is_send = this.form.is_send
        body.notification_title = this.form.notification_title
        body.notification_text = this.form.notification_text
      }

      const data = new FormData()

      if (!this.ismanuallyadd) {
        let img = this.form.image
        data.append('image', img)
      }

      if (this.form.video) {
        data.append('video', this.form.video)
      }

      data.append('data', JSON.stringify(body))
      const queryId = this.$route.query.id
      const request = !this.isEdit
        ? axios.post('/api/content', data)
        : axios.patch(`/api/content/${queryId}`, data)
      await request
        .then(async (result) => {
          let id

          if (this.isEdit) {
            id = queryId
          } else {
            id = result.data._id
          }

          await axios.post('/api/content/onUpdateContentText', {
            id,
            content_text: this.form.content_text,
          })

          this.onCancel()
          this.$toast.success('Saved successfully')
          this.$router.push('/subscription/exclusiveTraining')
        })
        .catch((err) => {
          this.onCancel()
          this.$toast.error(err.response.data.error.message)
        })
    },
    async initialUpdateItem() {
      const id = this.$route.query.id
      if (!id) return

      await axios.get(`api/content/${id}`).then((res) => {
        this.form = { ...this.form, ...res.data }

        this.form.inputtag = res.data.hashtag
        this.isEdit = true

        var file = { size: 123, name: 'Icon', type: 'image/png' }
        if (this.form.img_url) {
          var url = `${this.baseImageUrl}/${this.form.img_url}`
          this.$refs.myVueDropzone.manuallyAddFile(file, url)
        }
      })
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData()
      formData.append('image', file)

      await axios
        .post('api/content/ckupload_images', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((result) => {
          let url = result.data.url // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Exclusive Training Content</h4>
      </div>
      <div class="col-xl-6" v-if="!isEdit"> </div>
      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-form class="form-horizontal" @submit.prevent="formSubmit">
                <div class="row center">
                  <div class="col-7">
                    <b-form-group label-cols-lg="0">
                      <label>Title</label>
                      <b-form-input
                        id="title"
                        placeholder="Enter title..."
                        name="title"
                        type="text"
                        v-model="$v.form.content_title.$model"
                        :state="validateState('content_title')"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field</b-form-invalid-feedback
                      >
                    </b-form-group>
                    <b-form-group
                      label-cols-lg="0"
                      v-if="form.is_video && !isEdit"
                    >
                      <label>Video ID</label>
                      <b-form-input
                        id="title"
                        placeholder="Enter Video URL Link..."
                        name="title"
                        type="text"
                        v-model="form.video"
                      ></b-form-input>
                    </b-form-group>

                    <iframe
                      v-if="this.form.video_data"
                      class="center"
                      :src="
                        `https://player.vimeo.com/video/${this.form.video_data.vimeoid}`
                      "
                      width="640"
                      height="360"
                      frameborder="0"
                      allow="  fullscreen"
                      allowfullscreen
                    ></iframe>

                    <div>
                      <a
                        v-if="this.form.video_data"
                        :href="
                          `https://vimeo.com/manage/${this.form.video_data.vimeoid}/general`
                        "
                        target="_blank"
                        >Edit Video</a
                      >
                    </div>

                    <!-- <b-form-group label-cols-lg="0">
                      <label>Short description</label>
                      <b-form-input
                        placeholder="Short description..."
                        v-model="form.short_description"
                      ></b-form-input>
                    </b-form-group> -->
                    <b-form-group label-cols-lg="0">
                      <label>Content</label>

                      <vue-editor
                        :editorOptions="editorSettings"
                        height="300"
                        v-model="form.content_text"
                        :use-custom-image-handler="true"
                        @image-added="handleImageAdded"
                      ></vue-editor>
                    </b-form-group>

                    <b-form-group label-cols-lg="0" v-if="!form.is_video">
                      <label>
                        Image
                        <small class="color-text-small"
                          >Width 354 x Height 338. Just only one.</small
                        >
                      </label>

                      <vue-dropzone
                        @vdropzone-file-added="vfileAdded"
                        @vdropzone-file-added-manually="vfilemanuallyAdded"
                        acceptedFiles="image/*"
                        id="form-image"
                        ref="myVueDropzone"
                        :options="dropzoneOptions"
                        :state="validateState('image')"
                        aria-describedby="input-3-live-feedback"
                      ></vue-dropzone>
                      <b-form-invalid-feedback id="input-3-live-feedback"
                        >This is a required field</b-form-invalid-feedback
                      >
                    </b-form-group>

                    <!-- <b-form-group label-cols-lg="2" label="Hashtag :">
                      <div>
                        <multiselect
                          v-model="form.inputtag"
                          tag-placeholder="Add this as new tag"
                          placeholder="Search"
                          label="tag_name"
                          track-by="_id"
                          :options="options"
                          :multiple="true"
                        ></multiselect>
                      </div>
                    </b-form-group> -->

                    <b-form-group label-cols-lg="2" label="Status :">
                      <div>
                        <b-form-select
                          v-model="form.status"
                          :options="optionsStatus"
                        ></b-form-select>
                      </div>
                    </b-form-group>

                    <!-- <b-form-group label-cols-lg="2" label="Type :">
                      <div>
                        <b-form-select
                          v-model="form.is_paid"
                          :options="optionsType"
                        ></b-form-select>
                      </div>
                    </b-form-group> -->
                    <div v-if="!isEdit">
                      <b-form-group label-cols-lg="0">
                        <b-container class="bv-example-row">
                          <b-row>
                            <label style="color:#4B4B5A; font-weight: 400;"
                              >Send Notification *:</label
                            >

                            <b-form-checkbox
                              class="ml-2"
                              v-model="form.is_send"
                            ></b-form-checkbox>
                          </b-row>
                        </b-container>
                      </b-form-group>

                      <div v-if="form.is_send">
                        <b-form-group
                          label-cols-lg="2"
                          label="Notification Title:"
                        >
                          <div>
                            <b-form-input
                              v-model="form.notification_title"
                              placeholder="Enter Notification Title"
                            ></b-form-input>
                          </div>
                        </b-form-group>
                        <b-form-group
                          label-cols-lg="2"
                          label="Notification Text:"
                        >
                          <div>
                            <b-form-input
                              v-model="form.notification_text"
                              placeholder="Enter Notification Text"
                            ></b-form-input>
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                    <b-form-group>
                      <router-link to="/subscription/exclusiveTraining">
                        <button
                          type="button"
                          class="btn btn-danger float-right ml-2"
                          >Cancel</button
                        >
                      </router-link>
                      <button type="submit" class="btn btn-primary float-right"
                        >Save</button
                      >
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :on-cancel="onCancel"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </Layout>
</template>
